import axios from "axios";

export async function saveFile(file, body) {

    try {
        const response = axios(
            {
                method: 'PUT',
                url: `https://sl58u0na5m.execute-api.us-east-2.amazonaws.com/prod/pay-the-winners-lottery/${file}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                },
                data: body

            }

        );
        console.log(`Saved ${file}.`);
        return response;
    } catch (err) {
        console.log("That was an error!");
        console.log(err);
        return err.response;
    }

}

export function getFile(file) {

    try {
        const response = axios(
            {
                method: 'GET',
                url: `https://sl58u0na5m.execute-api.us-east-2.amazonaws.com/prod/pay-the-winners-lottery/${file}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                },
            }
        );
        console.log(`Retrieved ${file}.`);
        return response;
    } catch (err) {
        console.log("That was an error!");
        console.log(err);
        return err.response;
    }

}