import { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { getFile, saveFile } from './s3.apis';
import AddFunds from './AddFunds';
import { memberFile, lottoPoolInfoFile } from './constants';
import BuyTickets from './BuyTickets';


class App extends Component {

  state = {
    isFileLoadComplete: false,
    message: []
  };

  members = [];
  lottoInfo = [];

  componentDidMount() {

    Promise.all([getFile(memberFile), getFile(lottoPoolInfoFile)])
      .then((response) => {

        console.log("called file retrieval");
        this.members = response[0].data.filter(member => member.status === 'IN');
        this.lottoInfo = response[1].data;
        this.setState({ isFileLoadComplete: true });
      })

  }

  findMember = (array, memberId) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].memberId === memberId) return i;
    }
    return -1;
  }

  addFunds = (memberId, amount, date, status) => {
    this.clearMessage();
    let index = this.findMember(this.members, memberId);

    let updateMessage = [];
    console.table(this.members[index]);
    let priorAmount = this.members[index].balance;
    this.members[index].balance = Number(this.members[index].balance) + Number(amount);
    this.members[index]["lastFundUpdate"] = `$${amount}, ${date}`;
    if (status === 'OUT') {
      this.members[index]["status"] = status;
      updateMessage.push(`Status for ${this.members[index].firstName} (${this.members[index].memberId}) has been changed to ${status}.`)
    }

    console.table(this.members[index]);


    updateMessage.push(
      `Received $${amount} from ${this.members[index].firstName} (${this.members[index].memberId}). Balance has been updated from $${priorAmount} to $${this.members[index].balance}.`);

    let logDate = this.getDate();
    let fundLogFile = `log_${this.members[index].memberId}_${logDate.date}.json`

    Promise.all([saveFile(memberFile, this.members), saveFile(fundLogFile, updateMessage)])
      .then(([memberResponse, logResponse]) => {
        console.log("Member fund update status: ", memberResponse.status);
        console.log("Log status: ", logResponse.status);

        this.setState({ message: updateMessage });
      });

  }

  buyTickets = (draws, ticketCost) => {
    this.clearMessage();
    let perMemberCost = draws * ticketCost;
    console.log(draws, ticketCost);
    let uiMessage = [];
    uiMessage.push("Ticket Purchase Summary:");
    let ticketTotal = 0;
    let log = [];

    this.members.forEach(member => {
      if (Number(member.balance) < 0) uiMessage.push(`${member.firstName} has a negative balance.`);
      member.balance = Number(member.balance) - perMemberCost;
      log.push(`Tickets purchased for ${member.firstName} ${member.lastName.charAt(0)} (${member.memberId}) for $${perMemberCost}.`);
      ticketTotal += perMemberCost;
    })

    uiMessage.push(`Number of tickets purchased: ${draws * this.members.length}, cost: $${ticketTotal}.`);
    let logDate = this.getDate();
    let purchaseLogFile = `log_ticket_purchase_${logDate.date}_${logDate.time}.json`;
    this.lottoInfo[0].date = logDate.date;

    Promise.all([saveFile(memberFile, this.members), saveFile(purchaseLogFile, log), saveFile(lottoPoolInfoFile, this.lottoInfo)])
      .then(([memberResponse, logResponse, infoResponse]) => {
        console.log("Member fund update status: ", memberResponse.status);
        console.log("Log status: ", logResponse.status);
        console.log("Info status: ", infoResponse.status);

        this.setState({ message: uiMessage });
      });

  }

  clearMessage = () => this.setState({ message: [] });

  getDate = () => {
    let today = new Date();
    let value = [];
    return {
      "date": `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
      "time": `${today.getHours()}h${today.getMinutes()}m${today.getSeconds()}s`
    }
  }

  getValue = (item) => {
    let keys = Object.keys(item);
    return `${keys[0]}: ${keys[0] === 'balance' ? "$" : ""}${item[keys[0]]}`;
  }

  render() {
    return (

      <div>
        <header className="App-header">
          {this.lottoInfo.map( (item, index) => <div key={`info${index}`}>{this.getValue(item)}</div>)}
        </header>

        <Row className={this.state.message.length > 0 ? "message" : "hide"}>
          {this.state.message.map(message => <div key={message}>{message}</div>)}
          {/* {this.state.message} */}
        </Row>

        <div className={this.state.isFileLoadComplete ? "App" : "hide"}>
          {/* {this.members.map(member =>  <div>{member.firstName}</div>)} */}
          <AddFunds members={this.members
            // .filter(member => member.status === 'IN')
            .sort((member1, member2) => {
              if (member1.balance < member2.balance) return -1;
              else return 1;
            })
          }
            addFunds={this.addFunds} />
          <br />
          <BuyTickets buyTickets={this.buyTickets} />

        </div>
      </div>
    );
  }
}

export default App;
