import React from "react";
import { Component } from "react";
import { Container, Form, Row, Col, Button  } from "react-bootstrap";


class AddFunds extends Component {

    constructor() {
        super();
        this.state = {
            memberId: '',
            amount : '20',
            date: this.getDate()
        }
    }

    getDate = () => {
        let today = new Date();
        return `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });

    }

    handleFundsSubmit = (e) => {
        e.preventDefault();
        this.props.addFunds(e.target.elements.memberId.value,e.target.elements.amount.value, e.target.elements.date.value, e.target.elements.status.value );
        // e.target.reset();
    }

    render () {
        return (
            <Container fluid="md">
            <h2>Add Funds</h2>
            <Form onSubmit={this.handleFundsSubmit}>
                <Row>
                <Form.Group as={Col}>
                    <Form.Label>Member: </Form.Label>
                    <Form.Select
                         
                        id = "memberId"
                        name = "memberId"
                            onChange={(event) => this.handleUserInput(event)} >
                        { this.props.members.map(member => <option key={member.id} value={member.memberId}>{member.firstName} {member.lastName.charAt(0)}</option>)}
                        
                    </Form.Select>
                </Form.Group>
    
                <Form.Group as={Col}>
                    <Form.Label htmlFor="amount" >$ Amount: </Form.Label>
                    <Form.Control type="number" value={this.state.amount} id="amount" name="amount" 
                      onChange={(event) => this.handleUserInput(event)} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="date" >Date: </Form.Label>
                    <Form.Control type="text" value={this.state.date} id="date" name="date" 
                      onChange={(event) => this.handleUserInput(event)} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>Status: </Form.Label>
                    <Form.Select
                         
                         id = "status"
                         name = "status"
                             onChange={(event) => this.handleUserInput(event)} >
                         <option value="IN">IN</option>
                         <option value="OUT">OUT</option>
                     </Form.Select>
                </Form.Group>

                </Row>
                <Button type="submit">Add Funds</Button>       
        </Form>

        </Container >

        )
    }

}
export default AddFunds;